import { AccAddress, Coin, MsgExecuteContract } from '@terra-money/terra.js'
import keccak256 from 'keccak256'
import { constants } from './constants'
import { EmptyWalletAddress, InvalidName, TNSError, UnknownResolver } from './errors'
import { QueryBuilder } from './QueryBuilder'
import { TNS } from './TNS'
import { CreateCommitmentOptions, RegisterOptions, RegistryRecord } from './types'
import { getBaseName, getLabel, isTnsName, node } from './utils'

type QueryBuilderCallback = (queryBuilder: QueryBuilder) => QueryBuilder

export type NameConfig = {
  name: string
  tns: TNS
}

export class Name {
  public readonly name: string
  private readonly tns: TNS

  constructor(config: NameConfig) {
    if (!isTnsName(config.name)) throw new InvalidName(config.name)

    this.name = config.name
    this.tns = config.tns
  }

  async query(queryBuilderOrCallback: QueryBuilder | QueryBuilderCallback) {
    const queryBuilder =
      typeof queryBuilderOrCallback === 'function'
        ? queryBuilderOrCallback(new QueryBuilder(this.name))
        : queryBuilderOrCallback

    const { data, errors } = await this.tns.query([queryBuilder])

    return { data: data[this.name], errors }
  }

  /************************************************
   * Registrar related methods
   ***********************************************/

  async isAvailable() {
    const { data, errors } = await this.query(builder => builder.isAvailable())

    this.handleSingleError(errors)

    return data.isAvailable
  }

  async getExpires() {
    const { data, errors } = await this.query(builder => builder.getExpires())

    this.handleSingleError(errors)

    return data.expires
  }

  async getGracePeriod() {
    const { data, errors } = await this.query(builder => builder.getGracePeriod())

    this.handleSingleError(errors)

    return data.gracePeriod
  }

  async getOwner() {
    const { data, errors } = await this.query(builder => builder.getOwner())

    this.handleSingleError(errors)

    return data.owner
  }

  async getImage() {
    const { data, errors } = await this.query(builder => builder.getImage())

    this.handleSingleError(errors)

    return data.image
  }

  async getConfig() {
    const { data, errors } = await this.query(builder => builder.getConfig())

    this.handleSingleError(errors)

    return data.config
  }

  /************************************************
   * Registry related methods
   ***********************************************/

  async getRegistrar() {
    const { data, errors } = await this.query(builder => builder.getRegistrar())

    this.handleSingleError(errors)

    return data.registrar
  }

  async getResolver() {
    const { data, errors } = await this.query(builder => builder.getResolver())

    this.handleSingleError(errors)

    return data.resolver
  }

  async getEditor() {
    const { data, errors } = await this.query(builder => builder.getEditor())

    this.handleSingleError(errors)

    return data.editor
  }

  async getTTL() {
    const { data, errors } = await this.query(builder => builder.getTTL())

    this.handleSingleError(errors)

    return data.ttl
  }

  setRecord(record: RegistryRecord) {
    this.assertWalletAddress(this.tns.walletAddress)

    return new MsgExecuteContract(
      this.tns.walletAddress,
      constants[this.tns.network].contracts.registry,
      { set_record: { node: node(this.name), ...record } }
    )
  }

  setOwner(ownerAddress: string) {
    this.assertWalletAddress(this.tns.walletAddress)

    return new MsgExecuteContract(
      this.tns.walletAddress,
      constants[this.tns.network].contracts.registry,
      { set_owner: { node: node(this.name), owner: ownerAddress } }
    )
  }

  setResolver(resolverAddress: string) {
    this.assertWalletAddress(this.tns.walletAddress)

    return new MsgExecuteContract(
      this.tns.walletAddress,
      constants[this.tns.network].contracts.registry,
      { set_resolver: { node: node(this.name), resolver: resolverAddress } }
    )
  }

  setTTL(ttl: number) {
    this.assertWalletAddress(this.tns.walletAddress)

    return new MsgExecuteContract(
      this.tns.walletAddress,
      constants[this.tns.network].contracts.registry,
      { set_ttl: { node: node(this.name), ttl } }
    )
  }

  /************************************************
   * Resolver related methods
   ***********************************************/

  async getTerraAddress() {
    const { data, errors } = await this.query(builder => builder.getTerraAddress())

    this.handleSingleError(errors)

    return data.terraAddress
  }

  async getAddress(coinType: number) {
    const { data, errors } = await this.query(builder => builder.getAddress(coinType))

    this.handleSingleError(errors)

    return data.address?.[coinType]
  }

  async getTextData(key: string) {
    const { data, errors } = await this.query(builder => builder.getTextData(key))

    this.handleSingleError(errors)

    return data.textData?.[key]
  }

  async getContentHash() {
    const { data, errors } = await this.query(builder => builder.getContentHash())

    this.handleSingleError(errors)

    return data.contentHash
  }

  async setTerraAddress(terraAddress: AccAddress) {
    this.assertWalletAddress(this.tns.walletAddress)

    const resolverAddress = await this.getResolver()

    this.assertResolver(resolverAddress)

    return new MsgExecuteContract(this.tns.walletAddress, resolverAddress, {
      set_terra_address: { node: node(this.name), address: terraAddress },
    })
  }

  async setAddress(address: string, coinType: number) {
    this.assertWalletAddress(this.tns.walletAddress)

    const resolverAddress = await this.getResolver()

    this.assertResolver(resolverAddress)

    return new MsgExecuteContract(this.tns.walletAddress, resolverAddress, {
      set_address: { node: node(this.name), coin_type: coinType, address },
    })
  }

  async setTextData(key: string, value: string) {
    this.assertWalletAddress(this.tns.walletAddress)

    const resolverAddress = await this.getResolver()

    this.assertResolver(resolverAddress)

    return new MsgExecuteContract(this.tns.walletAddress, resolverAddress, {
      set_text_data: { node: node(this.name), key, value },
    })
  }

  async setContentHash(hash: string) {
    this.assertWalletAddress(this.tns.walletAddress)

    const resolverAddress = await this.getResolver()

    this.assertResolver(resolverAddress)

    return new MsgExecuteContract(this.tns.walletAddress, resolverAddress, {
      set_content_hash: { node: node(this.name), hash },
    })
  }

  /************************************************
   * Controller related methods
   ***********************************************/

  async getRentPrice(duration?: number) {
    const { data, errors } = await this.query(builder => builder.getRentPrice(duration))

    this.handleSingleError(errors)

    return data.rentPrice
  }

  async getCommitmentTimestamp(commitment: string) {
    const { data, errors } = await this.query(builder =>
      builder.getCommitmentTimestamp(commitment)
    )

    this.handleSingleError(errors)

    return data.commitmentTimestamp
  }

  commitment(options: CreateCommitmentOptions) {
    const {
      owner = this.tns.walletAddress,
      address = this.tns.walletAddress,
      secret,
      resolver = constants[this.tns.network].contracts.resolver,
    } = options

    this.assert(owner, new Error('owner is not specified in commitment'))
    this.assert(address, new Error('address is not specified in commitment'))

    const label = getLabel(this.name)

    // TODO: Check if there is a cleaner way
    const data: Uint8Array[] = [
      keccak256(Buffer.from(label, 'utf-8')),
      ...[owner, resolver, address, secret].map(str => new TextEncoder().encode(str)),
    ]

    const bytes = data.reduce<number[]>((acc, cur) => {
      for (let i = 0; i < cur.length; i++) {
        acc.push(cur[i])
      }
      return acc
    }, [])

    // TODO: Add keccak256 type declaration file (keccak256.d.ts)
    return keccak256(bytes).toString('hex') as string
  }

  commit(commitment: string) {
    this.assertWalletAddress(this.tns.walletAddress)

    const baseName = getBaseName(this.name)!

    const controllerAddress = constants[this.tns.network].contracts.controller[baseName]

    return new MsgExecuteContract(this.tns.walletAddress, controllerAddress, {
      commit: { commitment },
    })
  }

  async register(options: RegisterOptions) {
    this.assertWalletAddress(this.tns.walletAddress)

    const {
      secret,
      duration,
      owner = this.tns.walletAddress,
      resolver = constants[this.tns.network].contracts.resolver,
      address = this.tns.walletAddress,
    } = options

    const baseName = getBaseName(this.name)!

    const controllerAddress = constants[this.tns.network].contracts.controller[baseName]

    const rentPrice = await this.getRentPrice()

    return new MsgExecuteContract(
      this.tns.walletAddress,
      controllerAddress,
      {
        register: {
          name: getLabel(this.name),
          owner,
          duration,
          secret,
          resolver,
          address,
        },
      },
      [new Coin('uusd', rentPrice!)]
    )
  }

  /************************************************
   * Private methods
   ***********************************************/

  /**
   * Check if the value exists (not nil) with custom error.
   *
   * @param value - value to be checked
   *
   * @throws error specified
   */
  private assert(value: any | null | undefined, error: Error): asserts value {
    if (!value) throw error
  }

  /**
   * Check if walletAddress is set on the TNS instance.
   *
   * @param walletAddress - TNS's walletAddress property
   *
   * @throws {@link EmptyWalletAddress} if the walletAddress is not defined
   */
  private assertWalletAddress(walletAddress?: string): asserts walletAddress {
    if (!walletAddress) {
      throw new EmptyWalletAddress()
    }
  }

  /**
   * Check if resolverAddress exists.
   *
   * @param resolverAddress - Address of the resolver
   *
   * @throws {@link UnknownResolver} if failed to retrieve Resolver address
   */
  private assertResolver(resolverAddress?: string): asserts resolverAddress {
    if (!resolverAddress) {
      throw new UnknownResolver(this.name)
    }
  }

  /**
   * Handle whether to throw error for single query.
   *
   * @param errors - Errors returned from query
   *
   * @throws First {@link TNSError} that occurs in the query results
   */
  private handleSingleError(errors?: TNSError[]) {
    if (errors?.length) throw errors[0]
  }
}

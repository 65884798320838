type TNSErrorData = {
  tnsName: string
  path: string[]
  contract: string
  contractAddress: string
  queryMsg: any
}

export class TNSError extends Error {
  public readonly tnsName: TNSErrorData['tnsName']
  public readonly path: TNSErrorData['path']
  public readonly contract: TNSErrorData['contract']
  public readonly contractAddress: TNSErrorData['contractAddress']
  public readonly queryMsg: TNSErrorData['queryMsg']

  constructor(message: string, data: TNSErrorData) {
    super(message)
    this.name = this.constructor.name

    this.tnsName = data.tnsName
    this.path = data.path
    this.contract = data.contract
    this.contractAddress = data.contractAddress
    this.queryMsg = data.queryMsg
  }
}

export class EmptyWalletAddress extends Error {
  constructor() {
    super('walletAddress has not been set on the TNS instance')
    this.name = this.constructor.name
  }
}

export class UnknownResolver extends Error {
  constructor(name: string) {
    super(`Could not retrieve Resolver address for ${name}`)
    this.name = this.constructor.name
  }
}

export class InvalidName extends Error {
  constructor(name: string) {
    super(`"${name}" is not a valid TNS name`)
    this.name = this.constructor.name
  }
}

import { BaseName } from '../types'

/**
 * Check whether a given name is a valid TNS identifier.
 *
 * @param name - A TNS identifier such as "alice.ust"
 * @returns `true` if it is a valid TNS identifier, `false` otherwise
 *
 * @example
 * ```ts
 * isTnsName("alice.ust")  // true
 * isTnsName("alice.com")  // false
 * isTnsName(".ust")       // false
 * isTnsName(".alice.ust") // false
 * isTnsName("alice")      // false
 * ```
 */
export const isTnsName = (name: string) => {
  const baseNames: BaseName[] = ['ust']

  return baseNames.some(baseName => {
    const dotBaseName = '.' + baseName

    return !name.startsWith('.') && !name.includes('..') && name.endsWith(dotBaseName)
  })
}

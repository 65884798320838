import { PropertyPath } from 'lodash'
import { getBaseName, getLabel, labelhash, node } from './utils'

export type Message = {
  name: string
  key: PropertyPath
  contract: 'registrar' | 'registry' | 'resolver' | 'controller'
  queryMsg: any
  resultKey?: string
}

const ONE_YEAR = 60 * 60 * 24 * 365

export class QueryBuilder {
  private messages: Message[] = [
    // Extra query to prevent empty GraphQL query error
    {
      name: this.name,
      key: 'placeholder',
      contract: 'registry',
      queryMsg: { get_config: {} },
    },
  ]

  constructor(public readonly name: string) {}

  /**
   * Returns QueryBuilder's message.
   *
   * @return The messages of this QueryBuilder
   * @internal
   */
  getMessages(): readonly Message[] {
    return this.messages
  }

  /************************************************
   * Controller related queries
   ***********************************************/

  getCommitmentTimestamp(commitment: string) {
    this.messages.push({
      name: this.name,
      key: 'commitmentTimestamp',
      contract: 'controller',
      queryMsg: {
        commitment_timestamp: { commitment },
      },
      resultKey: 'timestamp',
    })

    return this
  }

  getRentPrice(duration = ONE_YEAR) {
    const label = getLabel(this.name)

    this.messages.push({
      name: this.name,
      key: 'rentPrice',
      contract: 'controller',
      queryMsg: {
        rent_price: { name: label, duration },
      },
      resultKey: 'price',
    })

    return this
  }

  /************************************************
   * Registrar related queries
   ***********************************************/

  isAvailable() {
    const label = getLabel(this.name)

    this.messages.push({
      name: this.name,
      key: 'isAvailable',
      contract: 'registrar',
      queryMsg: {
        is_available: { id: labelhash(label) },
      },
      resultKey: 'available',
    })

    return this
  }

  getExpires() {
    const label = getLabel(this.name)

    this.messages.push({
      name: this.name,
      key: 'expires',
      contract: 'registrar',
      queryMsg: {
        get_expires: { id: labelhash(label) },
      },
      resultKey: 'expires',
    })

    return this
  }

  getGracePeriod() {
    this.messages.push({
      name: this.name,
      key: 'gracePeriod',
      contract: 'registrar',
      queryMsg: {
        get_grace_period: {},
      },
      resultKey: 'grace_period',
    })

    return this
  }

  getOwner() {
    const label = getLabel(this.name)

    this.messages.push({
      name: this.name,
      key: 'owner',
      contract: 'registrar',
      queryMsg: {
        owner_of: { token_id: labelhash(label) },
      },
      resultKey: 'owner',
    })

    return this
  }

  getImage() {
    const label = getLabel(this.name)

    this.messages.push({
      name: this.name,
      key: 'image',
      contract: 'registrar',
      queryMsg: {
        nft_info: { token_id: labelhash(label) },
      },
      resultKey: 'image',
    })

    return this
  }

  /************************************************
   * Registry related queries
   ***********************************************/

  getRegistrar() {
    const baseName = getBaseName(this.name)

    this.messages.push({
      name: this.name,
      key: 'registrar',
      contract: 'registry',
      queryMsg: {
        get_record: { name: baseName },
      },
      resultKey: 'owner',
    })

    return this
  }

  getResolver() {
    this.messages.push({
      name: this.name,
      key: 'resolver',
      contract: 'registry',
      queryMsg: {
        get_record: { name: this.name },
      },
      resultKey: 'resolver',
    })

    return this
  }

  getEditor() {
    this.messages.push({
      name: this.name,
      key: 'editor',
      contract: 'registry',
      queryMsg: {
        get_record: { name: this.name },
      },
      resultKey: 'owner',
    })

    return this
  }

  getTTL() {
    this.messages.push({
      name: this.name,
      key: 'ttl',
      contract: 'registry',
      queryMsg: {
        get_record: { name: this.name },
      },
      resultKey: 'ttl',
    })

    return this
  }

  getConfig() {
    this.messages.push({
      name: this.name,
      key: 'config',
      contract: 'registry',
      queryMsg: {
        get_config: {},
      },
    })

    return this
  }

  /************************************************
   * Resolver related queries
   ***********************************************/

  getTerraAddress() {
    this.messages.push({
      name: this.name,
      key: 'terraAddress',
      contract: 'resolver',
      queryMsg: {
        get_terra_address: { node: node(this.name) },
      },
      resultKey: 'address',
    })

    return this
  }

  getTextData(...keys: string[]) {
    keys.forEach(key => {
      this.messages.push({
        name: this.name,
        key: ['textData', key],
        contract: 'resolver',
        queryMsg: {
          get_text_data: { node: node(this.name), key },
        },
        resultKey: 'data',
      })
    })

    return this
  }

  getContentHash() {
    this.messages.push({
      name: this.name,
      key: 'contentHash',
      contract: 'resolver',
      queryMsg: {
        get_content_hash: { node: node(this.name) },
      },
      resultKey: 'hash',
    })

    return this
  }

  getAddress(coinType: number) {
    this.messages.push({
      name: this.name,
      key: ['address', coinType],
      contract: 'resolver',
      queryMsg: {
        get_address: { node: node(this.name), coin_type: coinType },
      },
      resultKey: 'address',
    })

    return this
  }
}

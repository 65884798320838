import { BaseName, NetworkName } from './types'

export const constants: Constants = {
  mainnet: {
    mantleUrl: 'https://mantle.terra.dev',
    contracts: {
      reverseRecord: 'terra13efj2whf6rm7yedc2v7rnz0e6ltzytyhydy98a',
      registry: 'terra19gqw63xnt9237d2s8cdrzstn98g98y7hkl80gs',
      resolver: 'terra1uhr0lncf04nhwn5p2uaellepery0lgphl09ut8',
      controller: {
        ust: 'terra1jfk9saed6c4n40qwe54mcxmgs8kz4husly3jx4',
      },
      registrar: {
        ust: 'terra1fsfnnv08cgwcyfyh3p89rf44tfy4tlgnsapyda',
      },
    },
  },
  testnet: {
    mantleUrl: 'https://bombay-mantle.terra.dev',
    contracts: {
      reverseRecord: 'terra13efj2whf6rm7yedc2v7rnz0e6ltzytyhydy98a', // TODO: Change to testnet address
      registry: 'terra1fmmced3dms3ha2st4y2qj8w5v2zyel7xpg8wpq',
      resolver: 'terra1stt3dcf40cqqzaqsfu6mdy4amwp04e3tk7907p',
      controller: {
        ust: 'terra1d4668eunfrdj9t66n8zhkurdtgu7avrzuvgkn5',
      },
      registrar: {
        ust: 'terra1w9g7lacvel0r6stqpra9e3sj64tglz70h7sv72',
      },
    },
  },
}

type Constants = {
  [key in NetworkName]: {
    mantleUrl: string
    contracts: {
      reverseRecord: string
      registry: string
      resolver: string
      controller: {
        [key in BaseName]: string
      }
      registrar: {
        [key in BaseName]: string
      }
    }
  }
}

import { namehash } from './namehash'

/**
 * Generate the output of the namehash function in a form of number array
 * which is supported by the contract query.
 *
 * @param name - A TNS identifier such as "alice.ust"
 * @returns The result of namehash function in a number array format
 */
export const node = (name: string): number[] => {
  return Array.from(Uint8Array.from(namehash(name)))
}

import { BaseName } from '../types'

/**
 * Get the base name (top-level domain) of a given TNS identifier.
 *
 * @param name - A TNS identifier such as "alice.ust"
 * @returns The base name of the specified name
 *
 * @example
 * ```ts
 * getBaseName("alice.ust") // "ust"
 * getBaseName("alice")     // null
 * ```
 */
export const getBaseName = (name: string) => {
  const [baseName] = name.split('.').slice(-1)

  if (baseName === name) return null
  if (baseName === '') return null

  return baseName as BaseName
}

import keccak256 from 'keccak256'

/**
 * Generate a keccak256 hash result of a label.
 *
 * @param name - A label of TNS identifier such as "alice" (without the base name ".ust")
 * @returns The result of keccak256 hash on the specified label
 */
export const labelhash = (label: string): string => {
  return keccak256(label).toString('hex')
}

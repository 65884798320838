/**
 * Get the label of a given TNS identifier.
 *
 * @param name - A TNS identifier such as "alice.ust"
 * @returns The label of the specified name
 *
 * @example
 * ```ts
 * getLabel("alice.ust") // "alice"
 * ```
 */
export const getLabel = (name: string) => {
  const [label] = name.split('.').slice(-2)
  return label
}

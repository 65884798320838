import { Buffer } from 'buffer'
import keccak256 from 'keccak256'

/**
 * Generate a unique hash for any valid domain name.
 *
 * @param name - A TNS identifier such as "alice.ust"
 * @returns The result of namehash function in a {@link Buffer} form
 */
export const namehash = (name: string): Buffer => {
  if (name) {
    const [label, remainder] = name.split('.')
    return keccak256(Buffer.concat([namehash(remainder), keccak256(label)]))
  }

  return Buffer.from(''.padStart(64, '0'), 'hex')
}

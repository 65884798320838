import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client/core'
import { AccAddress } from '@terra-money/terra.js'
import fetch from 'cross-fetch'
import gql from 'graphql-tag'
import { jsonToGraphQLQuery } from 'json-to-graphql-query'
import { compact, flatMap, setWith, toPath } from 'lodash'
import { constants } from './constants'
import { TNSError } from './errors'
import { Name } from './Name'
import { Message, QueryBuilder } from './QueryBuilder'
import { NetworkName, Result } from './types'

type AddressMap = {
  [name: string]: {
    registrar?: string
    controller?: string
    resolver?: string
    registry: string
  }
}

export type TNSConfig = {
  walletAddress?: string
  mantleUrl?: string
  network?: NetworkName
}

export class TNS {
  public readonly walletAddress?: string
  public readonly mantleClient: ApolloClient<NormalizedCacheObject>
  public readonly network: NetworkName

  constructor(config: TNSConfig = {}) {
    this.network = config.network || 'mainnet'
    this.walletAddress = config.walletAddress

    const mantleUrl = config.mantleUrl || constants[this.network].mantleUrl

    this.mantleClient = new ApolloClient({
      link: new HttpLink({ uri: mantleUrl, fetch }),
      defaultOptions: { query: { fetchPolicy: 'network-only' } },
      cache: new InMemoryCache(),
    })
  }

  name(name: string) {
    return new Name({ name, tns: this })
  }

  async getName(address: AccAddress) {
    const query = jsonToGraphQLQuery({
      query: {
        name: {
          __aliasFor: 'WasmContractsContractAddressStore',
          __args: {
            ContractAddress: constants[this.network].contracts.reverseRecord,
            QueryMsg: JSON.stringify({ get_name: { address } }),
          },
          Result: true,
        },
      },
    })

    const { data } = await this.mantleClient.query({
      query: gql(query),
      errorPolicy: 'all',
    })

    const result = JSON.parse(data.name?.Result ?? null)

    return result?.name ?? null
  }

  async query(queryBuilders: QueryBuilder[]) {
    // Get required contract addresses
    const addressMap = await this.getAddressMap(queryBuilders)

    // Create GraphQL Query
    const messages = flatMap(queryBuilders, queryBuilder => queryBuilder.getMessages())

    const jsonGqlQuery = messages.reduce<any>((jsonGqlQuery, message, index) => {
      const alias = this.toAlias(index)

      const contractAddress = addressMap[message.name][message.contract]

      // Ignore query without contractAddress
      if (!contractAddress) return jsonGqlQuery

      return {
        ...jsonGqlQuery,
        [alias]: {
          __aliasFor: 'WasmContractsContractAddressStore',
          __args: {
            ContractAddress: contractAddress,
            QueryMsg: JSON.stringify(message.queryMsg),
          },
          Result: true,
        },
      }
    }, {})

    // Fetch results
    const apolloQueryResult = await this.mantleClient.query({
      query: gql(jsonToGraphQLQuery({ query: jsonGqlQuery }, { pretty: true })),
      errorPolicy: 'all',
    })

    // Parse results
    const data = messages.reduce<Record<string, Result>>((data, message, index) => {
      if (message.key === 'placeholder') return data

      const alias = this.toAlias(index)
      const path = this.toPath(message)

      const parsedData = JSON.parse(apolloQueryResult.data[alias]?.Result ?? null)

      // TODO: Convert to lodash/fp
      return typeof message.resultKey === 'string'
        ? setWith(data, path, parsedData?.[message.resultKey] ?? null, Object)
        : setWith(data, path, parsedData, Object)
    }, {})

    const errors = apolloQueryResult.errors
      ?.filter(error => !error.message.includes('code = Unknown'))
      ?.filter(error => error.path?.[0].toString())
      ?.map(error => {
        // Transform GraphQLError aliased path into more readable form
        const alias = error.path![0].toString()
        const index = this.fromAlias(alias)
        const message = messages[index]
        const path = this.toPath(message)

        const contractAddress = jsonGqlQuery[alias].__args.ContractAddress

        return new TNSError(error.message, {
          tnsName: message.name,
          path,
          contract: message.contract,
          contractAddress,
          queryMsg: message.queryMsg,
        })
      })

    return { data, errors }
  }

  private async getAddressMap(queryBuilders: QueryBuilder[]) {
    const addressMap: AddressMap = queryBuilders.reduce((acc, cur) => {
      return {
        ...acc,
        [cur.name]: {
          resolver: null,
          registry: constants[this.network].contracts.registry,
          registrar: constants[this.network].contracts.registrar['ust'],
          controller: constants[this.network].contracts.controller['ust'],
        },
      }
    }, {})

    // Create query builders for the required contract addresses
    const addressMapQueryBuilders = compact(
      queryBuilders.map(queryBuilder => {
        const contracts = queryBuilder.getMessages().map(message => message.contract)

        return contracts.includes('resolver')
          ? new QueryBuilder(queryBuilder.name).getResolver()
          : null
      })
    )

    // Fetch required contract addresses and populate addressMap
    if (addressMapQueryBuilders.length > 0) {
      const { data } = await this.query(addressMapQueryBuilders)

      Object.keys(data).forEach(name => {
        const value = data[name]
        addressMap[name].resolver = value.resolver
      })
    }

    return addressMap
  }

  /**
   * Create GraphQL alias from index.
   *
   * @param index - Index of the {@link Message}
   * @return GraphQL-safe alias string
   */
  private toAlias(index: number) {
    return `msg_${index}`
  }

  /**
   * Get {@link Message} index from GraphQL alias.
   *
   * @param alias - Alias string of the GraphQL query
   * @return Index of the {@link Message}
   */
  private fromAlias(alias: string) {
    return Number(alias.split('_')[1])
  }

  /**
   * Convert {@link Message} to {@link PropertyPath} to be used in the query result.
   *
   * @param message - A {@link Message} to be converted
   * @return Lodash's {@link PropertyPath}
   */
  private toPath(message: Message) {
    return [message.name, ...toPath(message.key)]
  }
}
